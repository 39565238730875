.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    .header {
        background-color: white;

        .navbar {
            padding: 0.5rem 3rem;
            @media (max-width: 768px) {
                padding: 0.5rem 1rem;
            }
            &_itme {
                @media (max-width: 768px) {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    gap: 1rem;
                }
                .btn {
                    @media (max-width: 768px) {
                        width: 50%;
                    }
                }
                .nav {
                    @media (max-width: 768px) {
                        display: none;
                    }
                    &-link {
                        position: relative;
                        color: black;
                        white-space: nowrap;
                        font-size: 20px;
                        font-weight: 800;
                        color: gray;
                        font-family: "Montserrat", sans-serif;
                        &.active {
                            color: black;
                            &::before {
                                display: block;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            bottom: -21px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 80%;
                            height: 5px;
                            background-color: #ffde17;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            display: none;
                        }
                        &:hover {
                            color: black;
                            &::before {
                                display: block;
                            }
                        }
                    }
                }
            }
            // &_itme-mob {
            //     margin-left: auto;
            //     @media (max-width: 768px) {
            //         display: block;
            //     }
            // }
        }
    }
    .body {
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;
        .sidebar {
            max-width: 150px;
            width: 100%;
            background: transparent linear-gradient(180deg, #4d4d4d, #000);
            padding: 2rem 1rem;
            @media (max-width: 768px) {
                position: fixed;
                bottom: 0;
                left: 0;
                max-width: 100%;
                z-index: 1000;
                padding: 0.5rem 2rem;
                padding-bottom: 0;
            }
            &_nav {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                list-style: none;
                gap: 1rem;
                margin: 0;
                padding: 0;
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    // width: 100%;
                    gap: 0rem;
                }
            }

            &_nav-link {
                text-decoration: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 1rem;
                position: relative;
                color: white;
                gap: 0.5rem;
                @media (max-width: 768px) {
                    gap: 0.25rem;
                }
                img {
                    @media (max-width: 768px) {
                        width: 40px;
                    }
                }
                &.active {
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 3px;
                        background-color: $primary;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                }
            }
        }
        .main {
            background: transparent linear-gradient(180deg, #cccccc, #ffffff);
            flex: 1 1 auto;
            overflow: auto;
            padding: 2rem;
            overflow: auto;
            @media (max-width: 768px) {
                padding: 1rem 0 6rem 0;
            }
            .content-tabs {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 0.5rem;
                @media (max-width: 768px) {
                    gap: .25rem;
                }
                .nav {
                    padding: 1px;
                    background-color: white;
                    border-radius: 5px;
                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    &-link {
                        min-width: 150px;
                        width: 100%;
                        border-radius: 0px;
                        padding: 0.375rem 0.75rem;
                        font-weight: 500;
                        border-radius: 5px;
                        color: #4d4d4d;
                        @media (max-width: 768px) {
                            min-width: 60px;
                            padding: 0.375rem 0.86rem;
                        }
                        &:hover {
                            border: 1px solid transparent;
                        }
                    }
                    &-link.active {
                        background: transparent linear-gradient(90deg, #4d4d4d, #000) 0 0 no-repeat padding-box;
                        color: white;
                    }
                }
            }
            .modal-btn {
                @media (max-width: 768px) {
                    width: 100%;
                }
                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;
                    // @media (max-width: 768px) {

                    // }
                }
            }
        }
    }
}

* {
    box-sizing: border-box;
}
