.tab-pane {
    padding: 2rem 0;
    .assets {
        &-content {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
            gap: 2rem;
        }
        &-card {
            width: 100%;
            padding: 1rem;
            margin: 0 auto;
            display: flex;
            border-radius: 8px;
            background-color: white;
            flex-direction: column;
            justify-content: space-between;
        }
        &-img {
            position: relative;
            font-weight: 600;
            min-height: 120px;
            max-height: 120px;
            margin-bottom: 1rem;
            display: flex;

            .icon {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        &-name {
            h6 {
                font-weight: 600;
                margin: 0;
            }
            span {
                color: gray;
            }
        }
        &-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 1rem;
            .buyBtn {
                min-width: 100px;
                padding: 4px;
                border-radius: 5px;
            }
            .buyBtn-group {
                button {
                    border: none;
                    width: 30px;
                    padding: 3px 10px;
                    border-radius: 5px;
                    i {
                        font-size: 12px;
                    }
                }
                span {
                    padding: 0 8px;
                }
            }
        }
    }
}

.lands {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 2.5rem;
    @media (max-width: 768px) {
        padding: 0;
    }
    .card {
        border: none;
        border-radius: 5px;
        &-img-top {
            border-radius: 5px;
            height: 170px;
            object-fit: cover;
        }
        &-name {
            border-bottom: 1px solid #ffde17;
            h5 {
                font-weight: 600;
                font-size: 16px;
            }
        }
        &-title {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 1.25rem;
            padding-top: 10px;
        }
        &-text {
            font-size: 14px;
            span {
                color: gray;
                font-size: 14px;
            }
            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
            }
        }
        &-footer {
            border: none;
            background-color: #fff;
            padding-top: 0;
            padding-bottom: 1rem;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .btn {
                width: 100%;
            }
        }
    }
}
