.navbar {
    padding: 0.5rem 3rem;
    @media (max-width: 992px) {
        padding: 0.5rem 0rem;
    }
    &-brand{
        img{
            @media (max-width: 768px){
            width: 120px;
            }
        }
        
    }
    .navbar_itme-mob {
        @media (max-width: 992px) {
            display: none;
        }
    }
    .navbar_itme-dex {
        display: flex;
        gap: .5rem;
        .btn{
            @media (max-width: 992px){
                width: 50%;
            }
        }
        @media (min-width: 992px) {
            display: none;
        }
    }
    .navbar-nav {
        .nav-item {
            padding: 0.5rem 1rem;
            .nav-link {
                position: relative;
                color: black;
                white-space: nowrap;
                font-size: 20px;
                font-weight: 800;
                color: gray;
                font-family: "Montserrat", sans-serif;
                &.active {
                    color: black;
                    &::before {
                        display: block;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -26px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 95%;
                    height: 5px;
                    background-color: #ffde17;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    display: none;
                    @media (max-width: 992px) {
                        bottom: -3px;
                        left: -20%;
                        transform: translateX(50%);
                        width: 36%;
                    }
                }
                &:hover {
                    color: black;
                    &::before {
                        display: block;
                    }
                }
            }
        }
    }
}
.header-text {
    background: transparent linear-gradient(62deg, #4d4d4d, #000);
    text-align: center;
    padding: 0.5rem;
    p {
        margin: 0;
        color: white;
    }
}
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}
