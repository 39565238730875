.claim-modal {
    .modal-dialog {
        @media (min-width: 576px) {
            max-width: 400px;
        }
    }
    .modal-header {
        border: none;
        .btn-close:focus {
            box-shadow: none;
        }
    }
    .modal-body {
        padding: 0.5rem 2rem 1.5rem 2rem;
        text-align: center;
        img {
            margin-bottom: 1rem;
        }
        p {
            color: black;
            font-weight: 600;
        }
        .form-control {
            background-color: rgba(0, 0, 0, 0.1);
            padding: 1rem;
            border: none;
            &:focus {
                box-shadow: none;
            }
        }
        textarea.form-control {
            min-height: calc(1.5em + 5rem + 2px);
        }
    }
    .btn {
        width: 100%;
    }
}
