$primary: #fbb040;
$secondary: #0d6f5d;
$white: #fff;
$danger: #eb2f06;
$dark: #090b27;
$dark-gray: #a7a7a7;
$light-gray: rgba(0, 90, 172, 0.08);

.btn {
    font-weight: 500;
}
.btn-primary {
    background-image: linear-gradient(270deg, #fbb040, #ffde17);
    color: #000;
    border: none;
}
.btn-primary:hover {
    color: #000;
    border: none;
}

.btn-primary:focus {
    box-shadow: none;
    color: #000;
}

.btn-primary:active:focus {
    box-shadow: none;
    color: #000;
}
.btn-secondary:focus {
    box-shadow: none;
}

.btn-secondary:active:focus {
    box-shadow: none;
}

.btn-secondary {
    background: transparent linear-gradient(90deg, #4d4d4d, #000) 0 0 no-repeat padding-box;
    border: none;
}
